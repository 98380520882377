<template>
  <footer>
    <div class="row inside" @click="navigate">
      <FooterNav category="zimmerei-holzbau"></FooterNav>
      <FooterNav category="unterhalt"></FooterNav>
      <FooterNav category="dienstleistungen"></FooterNav>
      <FooterNav category="unternehmen"></FooterNav>

      <div class="spacer"></div>
      <div class="contact">
        <h4>
          <router-link :to="{ name: 'contact' }">Kontakt</router-link>
        </h4>
        <p>
          Baumann + Partner AG<br />
          Rüchligweg 65<br />
          4125 Riehen<br />
          Tel. <a :href="$contact.tel">{{ $contact.phone }}</a
          ><br />
          <a :href="$contact.mailto">{{ $contact.mail }}</a>
          <br />
          <a href="https://www.instagram.com/baumann_zimmerei/" target="_blank">instagram</a>
          <br />
        </p>
        <div class="impressum">
          <router-link :to="{ name: 'imprint' }">Impressum</router-link>
        </div>
        <div class="datenschutz">
          <router-link :to="{ name: 'datenschutz' }">Datenschutz</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import VueScrollTo from "vue-scrollto";
import { setTimeout } from "timers";
import FooterNav from "@/components/FooterNav.vue";

export default {
  data() {
    return {
      routeHasChanged: false
    };
  },
  components: {
    FooterNav
  },
  watch: {
    $route: "onRouteChanged"
  },
  methods: {
    onRouteChanged() {
      this.routeHasChanged = true;
    },
    navigate(e) {
      // Workaround to scroll to the title when on the same page
      // Other scrolling behaviour is implemented in the router

      // Timeout is required to as onRouteChanged is called after `navigate`
      setTimeout(() => {
        if (!this.routeHasChanged && e.target.href) {
          const url = new URL(e.target.href);
          if (url.hash) {
            VueScrollTo.scrollTo(url.hash);
          } else {
            VueScrollTo.scrollTo("#app");
          }
        } else {
          this.routeHasChanged = false;
        }
      }, 1);
    }
  }
};
</script>

<style scoped>
a,
a:hover,
a:visited {
  color: #fff;
  text-decoration: none;
}
footer {
  background-color: #009fe3;
  color: #fff;
  padding: 1.25rem 0;
  margin-top: 1rem;
}
.inside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inside > div {
  margin-top: 1rem;
}

/* Spacer appers on large viewports only*/
.spacer {
  display: none;
}

/* 2 items per row */
@media (min-width: 839px) {
  .inside {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .inside > div {
    width: 50%;
  }
  /* Move the last row (contat) to the left */
  .inside > .contact {
    width: 100%;
    margin-left: 50%;
  }
}

/* 1 row with 5-6 items*/
@media (min-width: 1100px) {
  .inside > div {
    margin-top: 0;
    width: 16.666%;
  }
  .inside > .contact {
    width: 16.666%;
    margin-left: 0;
    min-width: 13rem;
  }
}

/* Show spacer */
@media (min-width: 1200px) {
  .inside > .spacer {
    display: block;
    flex-basis: 1rem;
  }
}

.impressum {
  margin-top: 0.5rem;
}
</style>
