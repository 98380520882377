<template>
  <vue-carousel
    class="carousel"
    :perPage="1"
    :navigationEnabled="true"
    navigationNextLabel=""
    navigationPrevLabel=""
    :scrollPerPage="false"
    :mouseDrag="false"
    :paginationEnabled="false"
    :perPageCustom="multiple ? [[768, 3]] : []"
    @pageChange="onPageChange"
  >
    <slot></slot>
  </vue-carousel>
</template>
<script>
import { Carousel } from "vue-carousel";

export default {
  props: {
    multiple: Boolean
  },
  components: {
    "vue-carousel": Carousel
  },
  methods: {
    onPageChange() {
      // Call stop on all inactive slides
      const carousel = this.$children[0];
      for (let i = 0; i < carousel.slideCount; i++) {
        const slide = carousel.getSlide(i);
        if (!slide.isActive) {
          slide.$children.forEach(child => {
            if (child.stop) child.stop();
          });
        }
      }
    }
  }
};
</script>
<style>
.carousel {
  /* Because slides have margins... */
  margin-left: -1rem;
  margin-right: -1rem;
}
.VueCarousel-inner {
  align-items: center;
}

/* Hack in the next/prev arrow buttons */
.VueCarousel-navigation-button:focus {
  outline: none !important;
}
.VueCarousel-navigation-button {
  border: solid #ffffff !important;
  border-width: 0 0.3rem 0.3rem 0 !important;
  display: inline-block !important;
  padding: 0.8rem !important;
  margin-left: 0 !important;
  box-shadow: 2px 2px 1px #888888 !important;
}
.VueCarousel-navigation-button.VueCarousel-navigation-next {
  transform: translateY(-50%) translateX(-100%) rotate(-45deg) !important;
}
.VueCarousel-navigation-button.VueCarousel-navigation-prev {
  transform: translateY(-50%) translateX(100%) rotate(135deg) !important;
}
.VueCarousel-navigation-button.VueCarousel-navigation--disabled {
  display: none !important;
}

/* no-script styling */
.noJs .VueCarousel-navigation {
  display: none;
}
.noJs .VueCarousel-slide {
  flex-basis: 100%;
}
.noJs .VueCarousel-slide:not(:first-of-type) {
  display: none;
}
</style>
