import { render, staticRenderFns } from "./ClickVideo.vue?vue&type=template&id=cc2893b2&scoped=true&"
import script from "./ClickVideo.vue?vue&type=script&lang=js&"
export * from "./ClickVideo.vue?vue&type=script&lang=js&"
import style0 from "./ClickVideo.vue?vue&type=style&index=0&id=cc2893b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc2893b2",
  null
  
)

component.options.__file = "ClickVideo.vue"
export default component.exports