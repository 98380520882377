<template>
  <div :class="{ wrapper: true, clicked: clicked }">
    <iframe
      v-if="clicked"
      :src="src + '?rel=0&amp;showinfo=0&amp;autoplay=1'"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    ></iframe>
    <ResponsiveImage
      v-else
      @click.native="clicked = true"
      class="video"
      :image="image"
      :alt="alt || 'Video'"
    ></ResponsiveImage>
  </div>
</template>

<script>
export default {
  props: {
    image: Object,
    src: String,
    alt: String,
    visible: Boolean
  },
  data() {
    return {
      clicked: false
    };
  },
  mounted() {
    this.$on("visibilityChanged", visibility => {
      if (this.clicked && !visibility) {
        this.clicked = false;
      }
    });
  },
  methods: {
    stop() {
      // Force stop the video
      this.clicked = false;
    }
  }
};
</script>
<style scoped>
img {
  width: 100%;
}
.wrapper {
  position: relative;
}
.wrapper.clicked {
  width: 100%;
  padding-top: 66.66%; /* 3:2 Aspect Ratio */
}
iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}
.video {
  cursor: pointer;
}
</style>
