<template>
  <a href="https://www.youtube.com/watch?v=Ys9Wmg696wQ" target="_blank">
    <div class="wrap">
        <vue-carousel
          class="carousel"
          :perPage="1"
          :autoplay="true"
          :autoplayTimeout="5000"
          :loop="true"
          :navigationEnabled="false"
          navigationNextLabel=""
          navigationPrevLabel=""
          :scrollPerPage="false"
          :mouseDrag="false"
          :paginationEnabled="false"
        >
          <vue-carousel-slide>
            <div>
              <ResponsiveImage 
              :image="
                require('@/assets/news/holzbau-plus.jpg')
              "
              alt="Symbolbild 'Qualitätslabel'"></ResponsiveImage>
              <div class="label">
                Qualitätslabel Holzbau Plus
                <p class="small">Hier geht’s zum Video</p>
              </div>
            </div>
          </vue-carousel-slide>
          <vue-carousel-slide>
            <div>
              <ResponsiveImage 
              :image="
                require('@/assets/news/holzbau-plus-2.jpg')
              "
              alt="Symbolbild 'Qualitätslabel'"></ResponsiveImage>
              <div class="label">
                Qualitätslabel Holzbau Plus
                <p class="small">Hier geht’s zum Video</p>
              </div>
            </div>
          </vue-carousel-slide>
          <vue-carousel-slide>
            <div>
              <ResponsiveImage 
              :image="
                require('@/assets/news/holzbau-plus-3.png')
              "
              alt="Symbolbild 'Qualitätslabel'"></ResponsiveImage>
              <div class="label">
                Qualitätslabel Holzbau Plus
                <p class="small">Hier geht’s zum Video</p>
              </div>
            </div>
          </vue-carousel-slide>
        </vue-carousel>
      <!-- </div> -->
    </div>
  </a>
</template>
<script>
import { Slide } from "vue-carousel";
import { Carousel } from "vue-carousel";

export default {
  components: {
    "vue-carousel": Carousel,
    "vue-carousel-slide": Slide
  }
};
</script>
<style scoped>
a {
  display: block;
}
.carousel,
.VueCarousel-wrapper {
  height: 100%;
}

.wrap {
  position: relative;
  width: 100%;
  height: 25vw;
  overflow: hidden;
}
.small {
  font-size: 1rem;
}

.inner {
  position: absolute;
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.label {
  z-index: 2;
  position: absolute;
  top: 40%;
  min-height: 100%;
  min-width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  text-shadow: -1px 0 #636363, 0 1px #636363, 1px 0 #636363, 0 -1px #636363;

  line-height: 1.5rem;
}
.inner img {
  /* Disable "direct" actions */
  pointer-events: none;
  user-select: none;

  position: absolute;
  min-height: 100%;
  min-width: calc(10rem * 4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 500ms ease-in-out;
}
iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
@media (min-width: 839px) {
  .label {
    top: 50%;
    font-size: 2rem;
    line-height: 2rem;
  }
  .small {
    font-size: 1.5rem;
  }

  .inner img {
    min-width: calc(20rem * 4);
  }
}
</style>
