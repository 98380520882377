<template>
  <div :class="{ hamburger: true, open: open }">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>
export default {
  props: ["open"]
};
</script>

<style scoped>
.hamburger {
  width: 3rem;
  height: 2.3rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger span {
  position: absolute;
  height: 0.3rem;
  width: 100%;
  background: #009fe3;
  border-radius: 0.3rem;
  left: 0;
  transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

.hamburger span:nth-child(2) {
  top: 1rem;
  transform-origin: left center;
}

.hamburger span:nth-child(3) {
  top: 2rem;
  transform-origin: left center;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  left: 0.4rem;
}

.hamburger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 2.1rem;
  left: 0.4rem;
}
</style>
