<template>
  <div class="wrapper">
    <h2>Kontakt</h2>
    <noscript class="error">
      Sie müssen JavaScript aktivieren, um das Kontaktformular nutzen zu können.
    </noscript>
    <div class="contact">
      <p>
        Wir freuen uns auf Ihre Kontaktaufnahme.
      </p>
      <div v-if="isSending" class="sending">
        Ihre Anfrage wird übertragen, bitte warten Sie einen Moment...
      </div>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="successfull" class="success">
        Besten Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen melden.
      </div>

      <form action="" @submit.prevent="onSubmit()" v-if="!successfull">
        <fieldset :disabled="isSending">
          <label for="name">Ihr Name *</label>
          <input
            v-model="form.name"
            required
            id="name"
            type="text"
            placeholder="Max Mustermann"
            minlength="3"
          />
          <label for="mail">Ihre E-Mail *</label>
          <input
            v-model="form.mail"
            required
            id="mail"
            type="email"
            placeholder="max.mustermann@beispiel.ch"
            minlength="3"
          />
          <label for="phone">Ihre Telefonnummer</label>
          <input
            v-model="form.phone"
            id="phone"
            type="tel"
            placeholder="061 123 45 67"
            pattern="\+?[0-9\s]{10,17}"
          />
          <label for="request">Ihr Anliegen *</label>
          <textarea
            v-model="form.request"
            id="request"
            required
            minlength="3"
            placeholder="Wie und bei welchem Objekt können wir Sie unterstützen?"
          ></textarea>
          <button type="submit">Absenden</button>
        </fieldset>
        <p>Alle mit einem * markierten Felder müssen ausgefüllt werden.</p>
      </form>
    </div>
    <div>
      <h3>Anschrift</h3>
      <p>Rüchligweg 65, 4125 Riehen</p>
      <h3>E-Mail</h3>
      <p>
        <a :href="$contact.mailto">{{ $contact.mail }}</a>
      </p>
      <h3>Telefon</h3>
      <p>
        <a :href="$contact.tel">{{ $contact.phone }}</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        mail: "",
        phone: "",
        request: "",
        token: ""
      },
      isSending: false,
      successfull: false,
      error: false
    };
  },
  methods: {
    xhrRequest(method, url, data) {
      return new Promise((resolve, reject) => {
        try {
          const xhr = new XMLHttpRequest();
          xhr.open(method, url, true);
          if (data) {
            xhr.setRequestHeader("Content-type", "application/json");
          }
          xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
              if (!xhr.status == 200) {
                reject("Request has failed - status: " + xhr.status);
                return;
              }
              try {
                resolve(JSON.parse(xhr.responseText));
              } catch (e) {
                reject("Failed to parse response!");
              }
            }
          };
          xhr.send(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    postJSON(url, data) {
      return this.xhrRequest("POST", url, JSON.stringify(data));
    },
    getJSON(url) {
      return this.xhrRequest("GET", url);
    },
    onSubmit() {
      this.isSending = true;
      this.successfull = false;
      this.error = false;

      this.getJSON("/contact.php")
        .then(response => {
          if (response.status !== "OK") {
            if (!response.error) throw new Error();
            this.error = response.error;
            this.isSending = false;
            return;
          }
          this.form.token = response.token;
          return this.postJSON("/contact.php", this.form).then(response => {
            if (response.status !== "OK") {
              if (!response.error) throw new Error();
              this.error = response.error;
            } else {
              this.successfull = true;
            }
            this.isSending = false;
          });
        })
        .catch(() => {
          this.error =
            "Entschuldigung, ein unbekannter Fehler ist aufgetreten! Bitte kontaktieren Sie uns via E-Mail oder Telefon.";
          this.isSending = false;
        });
    }
  }
};
</script>

<style scoped>
.sending,
.success,
.error {
  text-align: center;
  color: #ffffff;
  padding: 1rem;
}
.sending,
.success {
  background-color: #42b983;
}
.error {
  background-color: #b20000;
}
input,
textarea {
  width: 100%;
  border: 1px solid #636363;
  margin: 0.25rem 0 0.25rem 0;
  padding: 1rem;
  font-family: Arial, Verdana, sans-serif;
}

textarea {
  width: calc(100% - 2rem);

  min-height: 10rem;
  line-height: 150%;
  resize: vertical;
}
button {
  border: 1px solid #636363;
  padding: 0.5rem;
  background-color: #009fe3;
  color: white;
  font-weight: bold;
}
button:hover {
  background-color: white;
  color: #636363;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.wrapper > * {
  width: 100%;
}
form {
  margin-top: 1rem;
}
fieldset {
  border: 0;
  padding: 0;
}
@media (min-width: 839px) {
  .wrapper > div {
    width: 50%;
  }
  form,
  .sending,
  .success,
  .error {
    margin-right: 2rem;
  }
}
.noJs .contact {
  display: none;
}
</style>
