<template>
  <img
    :srcset="image.srcSet"
    :src="image.src"
    :sizes="sizes"
    :width="original.width"
    :height="original.height"
  />
</template>
<script>
export default {
  props: {
    image: Object
  },
  computed: {
    original() {
      return this.image.images[this.image.images.length - 1];
    },
    sizes() {
      const lastIdx = this.image.images.length - 1;
      return this.image.images
        .map((img, idx) => {
          if (idx === lastIdx) {
            return `${img.width}px`;
          }
          return `(max-width: ${img.width}px) ${img.width}px,`;
        })
        .join("");
    }
  },
  mounted() {
    this.image.images[this.image.images.length - 1];
  }
};
</script>
