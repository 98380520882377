<template>
  <div>
    <h4>
      <router-link :to="{ name: category }">{{
        $content[category].index.title
      }}</router-link>
    </h4>
    <nav>
      <ul>
        <li v-for="item in $content[category].navItems" :key="item.id">
          <router-link :to="{ name: category, hash: `#${item.id}` }">{{
            item.title
          }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  props: ["category"]
};
</script>

<style scoped>
a,
a:hover,
a:visited {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

/* Visual tweak when the line breaks */
h4,
li {
  padding-left: 0.4rem;
  text-indent: -0.4rem;
}
</style>
