<template>
  <div class="wrapper">
    <h2>20 Jahre Vielfalt im Holzbau als Memory</h2>
    <p v-if="gameOver" class="gratulation">
      <b>Gratulation</b>, Sie haben alle Paare gefunden!
      <a @click="restart()">Klicken Sie hier um neu zu starten.</a>
    </p>
    <p v-else>
      Entdecken Sie 20 Jahre Vielfalt im Holzbau, indem Sie die passenden Paare
      suchen. Klicken Sie auf eine der Karten um diese umzudrehen.
    </p>
    <div class="cards">
      <div
        v-for="card in cards"
        :key="card.key"
        class="card"
        :class="card.state"
        @click="clicked(card)"
      >
        <ResponsiveImage
          :image="require('@/assets/content/memory/' + card.src)"
        ></ResponsiveImage>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      matches: 0,
      cards: this.shuffleArray([
        { key: 1, value: "baum", src: "baum1.jpg", state: "covert" },
        { key: 2, value: "baum", src: "baum2.jpg", state: "covert" },
        {
          key: 3,
          value: "bienenhaus",
          src: "bienenhaus1.jpg",
          state: "covert"
        },
        {
          key: 4,
          value: "bienenhaus",
          src: "bienenhaus2.jpg",
          state: "covert"
        },
        { key: 5, value: "burgweg", src: "burgweg1.jpg", state: "covert" },
        { key: 6, value: "burgweg", src: "burgweg2.jpg", state: "covert" },
        { key: 7, value: "gauben", src: "gauben1.jpg", state: "covert" },
        { key: 8, value: "gauben", src: "gauben2.jpg", state: "covert" },
        { key: 9, value: "hechtweg", src: "hechtweg1.jpg", state: "covert" },
        { key: 10, value: "hechtweg", src: "hechtweg2.jpg", state: "covert" },

        {
          key: 11,
          value: "helsinkistrasse",
          src: "helsinkistrasse1.jpg",
          state: "covert"
        },
        {
          key: 12,
          value: "helsinkistrasse",
          src: "helsinkistrasse2.jpg",
          state: "covert"
        },
        { key: 15, value: "pavillon", src: "pavillon1.jpg", state: "covert" },
        { key: 16, value: "pavillon", src: "pavillon2.jpg", state: "covert" },

        {
          key: 17,
          value: "schlossstrasse",
          src: "schlossstrasse1.jpg",
          state: "covert"
        },
        {
          key: 18,
          value: "schlossstrasse",
          src: "schlossstrasse2.jpg",
          state: "covert"
        },
        {
          key: 19,
          value: "oberdorfstrasse",
          src: "oberdorfstrasse1.jpg",
          state: "covert"
        },
        {
          key: 20,
          value: "oberdorfstrasse",
          src: "oberdorfstrasse2.jpg",
          state: "covert"
        },
        {
          key: 21,
          value: "waisenhaus1",
          src: "waisenhaus1.jpg",
          state: "covert"
        },
        {
          key: 22,
          value: "waisenhaus1",
          src: "waisenhaus2.jpg",
          state: "covert"
        }
      ]),
      firstChoice: null,
      secondChoice: null
    };
  },
  computed: {
    gameOver: function() {
      return this.matches == this.cards.length / 2;
    }
  },
  methods: {
    clicked(selectedCard) {
      if (this.gameOver) {
        this.restart();
      } else if (
        selectedCard.state == "match" ||
        selectedCard.state == "visible"
      ) {
        // Does not make sense...
      } else if (!this.firstChoice) {
        this.firstChoice = selectedCard;
        this.firstChoice.state = "visible";
      } else if (!this.secondChoice) {
        this.secondChoice = selectedCard;
        this.firstChoice.state = "visible";
        if (this.firstChoice.value == this.secondChoice.value) {
          this.firstChoice.state = "match";
          this.secondChoice.state = "match";
          this.matches++;
          this.clear();
        } else {
          this.firstChoice.state = "no_match";
          this.secondChoice.state = "no_match";
          console.log("No match!");
          this.timeout = setTimeout(this.clear, 2000);
        }
      } else {
        this.clear();
      }
    },
    restart() {
      this.firstChoice = null;
      this.secondChoice = null;
      this.matches = 0;
      this.cards.forEach(card => {
        card.state = "covert";
      });
      this.shuffleArray(this.cards);
    },
    clear() {
      clearTimeout(this.timeout);
      if (
        this.firstChoice &&
        this.secondChoice &&
        this.firstChoice.value != this.secondChoice.value
      ) {
        this.firstChoice.state = "covert";
        this.secondChoice.state = "covert";
      }

      this.firstChoice = null;
      this.secondChoice = null;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  }
};
</script>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  user-select: none;
}
.card {
  border: 2px solid #6363631a;
  position: relative;
  width: 30%;
  margin-top: 1rem;
}
.card > img {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media (min-width: 839px) {
  .card {
    width: 24%;
  }
}
@media (min-width: 1100px) {
  .card {
    width: 19%;
  }
}
.card.covert {
  background-color: #6363631a;
}
.card.covert > img {
  visibility: hidden;
}
p {
  text-align: center;
}
</style>
