<template>
  <div class="home">
    <Welcome class="row" />

    <div class="index-tiles">
      <IndexTile title="Zimmerei und Holzbau" link="zimmerei-holzbau" />
      <IndexTile title="Unterhalt" link="unterhalt" />
      <IndexTile title="Dienstleistungen" link="dienstleistungen" />
    </div>

    <News></News>

    <div class="row angebot">
      <h2>Aus unserem Angebot</h2>
      <Carousel :multiple="true">
        <template
          v-for="category in [
            'zimmerei-holzbau',
            'dienstleistungen',
            'unterhalt'
          ]"
        >
          <Slide
            v-for="item in $content[category].navItems"
            :key="category + item.id"
          >
            <router-link :to="{ name: category, hash: `#${item.id}` }">
              <ResponsiveImage
                :image="contentAsset(category, item, item.cover)"
                :alt="item.title"
              ></ResponsiveImage>
            </router-link>
            <h3>
              <router-link :to="{ name: category, hash: `#${item.id}` }">{{
                item.title
              }}</router-link>
            </h3>
          </Slide>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Welcome from "@/components/Welcome";
import IndexTile from "@/components/IndexTile";
import Carousel from "@/components/Carousel.vue";
import Slide from "@/components/Slide.vue";
import News from "@/components/News.vue";

export default {
  name: "home",
  components: {
    Welcome,
    IndexTile,
    Carousel,
    News,
    Slide
  }
};
</script>
<style scoped>
h3 {
  margin-top: 0;
  text-align: center;
}
h3 > a {
  text-decoration: none;
}

.index-tiles {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 1rem 0 0 0;
}

@media (min-width: 839px) {
  .index-tiles {
    flex-direction: row;
    align-content: stretch;
  }
}

.noJs .angebot {
  display: none;
}
</style>
