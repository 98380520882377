<template>
  <div class="wrapper">
    <h2>Eine neue Dachgaube für mehr Wohnqualität.</h2>
    <h3>Welche Gaube ist neu?</h3>
    <div class="interactive">
      <ResponsiveImage
        :image="require('@/assets/content/neue-gaube/vor-umbau-2.jpg')"
      ></ResponsiveImage>
      <div class="option option-1" @click="left">
        <div class="option-text">Klicken um zu bestätigen</div>
      </div>
      <div class="option option-2" @click="right">
        <div class="option-text">Klicken um zu bestätigen</div>
      </div>
    </div>
    <div v-if="showResolution" class="resolution" id="resolution">
      <h2 v-if="correctAnswer">Gratulation, Sie haben richtig geraten!</h2>
      <h2 v-else>Leider falsch, tatsächlich ist die rechte Gaube neu.</h2>
      <h3>Hier das Beweisfoto:</h3>
      <ResponsiveImage
        :image="require('@/assets/content/neue-gaube/vor-umbau.jpg')"
      ></ResponsiveImage>
    </div>
  </div>
</template>
<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      showResolution: false,
      correctAnswer: false
    };
  },
  methods: {
    left() {
      this.correctAnswer = false;
      this.showResolution = true;
      setTimeout(() => VueScrollTo.scrollTo("#resolution"));
    },
    right() {
      this.correctAnswer = true;
      this.showResolution = true;
      setTimeout(() => VueScrollTo.scrollTo("#resolution"));
    }
  }
};
</script>
<style scoped>
.interactive {
  position: relative;
}
.option {
  position: absolute;
  border: 0.25rem solid #009fe3;
  transition: 0.5s ease;
}
@media (min-width: 839px) {
  .option {
    border: 0.5rem solid #009fe3;
  }
}
.option-1 {
  top: 15%;
  left: 35%;
  height: 35%;
  width: 20%;
}
.option-2 {
  top: 15%;
  left: calc(55% + 2px);
  height: 35%;
  width: 20%;
}
.option:hover {
  background-color: rgba(0, 140, 186, 0.5);
  color: white;
  cursor: pointer;
}
.option-text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.option:hover > .option-text {
  opacity: 1;
}
</style>
