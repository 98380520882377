<template>
  <li class="nav-main-entry">
    <template v-if="category">
      <router-link :to="{ name: category }">{{
        $content[category].index.title
      }}</router-link>
      <ul class="popover">
        <li v-for="item in $content[category].navItems" :key="item.id">
          <router-link :to="{ name: category, hash: `#${item.id}` }">{{
            item.title
          }}</router-link>
        </li>
      </ul>
    </template>
    <router-link v-else :to="to">
      <slot></slot>
    </router-link>
  </li>
</template>
<script>
export default {
  props: ["category", "to"]
};
</script>

<style scoped>
.nav-main-entry > a {
  display: block;
  font-size: 1rem;
}

.nav-main-entry > a {
  color: #636363;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding: 0.4rem 0rem;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.nav-main-entry > a.router-link-active,
.nav-main-entry > a:hover {
  color: #009fe3;
}

.popover {
  max-height: 0;
  width: 0;
  overflow: hidden;
  list-style: none;
  padding-left: 0;
}

@media (min-width: 839px) {
  /* Show submenu when hovering... */
  .nav > li {
    position: relative;
    max-width: 100%;
  }
  li:hover .popover {
    position: absolute;
    width: 10rem;
    left: 50%;
    margin-left: -5rem;

    background-color: #009fe3;
    z-index: 1;

    margin-top: -1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    max-height: 20rem;
    transition: max-height 300ms linear;
  }
  .popover li {
    line-height: 1.25rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  li:hover .popover a {
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
}
</style>
