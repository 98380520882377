import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home.vue";
import Contact from "./pages/Contact.vue";
import Imprint from "./pages/Imprint.vue";
import Privacy from "./pages/Privacy.vue";
import Jobs from "./pages/Jobs.vue";
import Content from "./pages/Content.vue";
import Nistkasten from "./pages/Nistkasten.vue";
import Bienenhaus from "./pages/Bienenhaus.vue";
import NeueGaube from "./pages/NeueGaube.vue";
import Fischergalgen from "./pages/Fischergalgen.vue";
import ZwanzigJahreVielfalt from "./pages/ZwanzigJahreVielfalt.vue";
import AuffrischungTerrassendeck from "./pages/AuffrischungTerrassendeck.vue";
import PageNotFound from "./pages/PageNotFound.vue";
import VueScrollTo from "vue-scrollto";
import { updateMetaTag } from "./utils";

let initialized = false;
let matomo;

function initialize(content) {
  Vue.use(Router);
  const jobsOffers = content["jobs"].items;
  const jobs = Object.keys(jobsOffers)
    .filter(key => jobsOffers[key].hidden !== true)
    .map(key => {
      const jobOffer = jobsOffers[key];
      return {
        path: "/jobs/" + jobOffer.key,
        component: Jobs,
        meta: {
          title: jobOffer.title,
          description: "Stelleninserat für " + jobOffer.title,
          jumbotron: "undefined",
          pdf: jobOffer.pdf,
          body: jobOffer.__content
        }
      };
    });
  const router = new Router({
    mode: "history",
    routes: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "/kontakt/",
        pathToRegexpOptions: { strict: true },
        name: "contact",
        component: Contact,
        meta: {
          title: "Kontakt",
          description: "Wir freuen uns auf Ihre Kontaktaufnahme."
        }
      },
      {
        path: "/impressum/",
        pathToRegexpOptions: { strict: true },
        name: "imprint",
        component: Imprint,
        meta: {
          title: "Impressum",
          description: "Impressum - Verantwortlich für die Inhalte dieser Seite"
        }
      },
      {
        path: "/datenschutz/",
        pathToRegexpOptions: { strict: true },
        name: "datenschutz",
        component: Privacy,
        meta: {
          title: "Datenschutz",
          jumbotron: "undefined",
          description: "Unsere Datenschutzbestimmungen"
        }
      },
      ...jobs,
      {
        path: "/jobs",
        redirect: { name: "unternehmen", hash: `#jobs` }
      },
      {
        path: "/unternehmen/",
        pathToRegexpOptions: { strict: true },
        name: "unternehmen",
        component: Content,
        props: {
          category: "unternehmen"
        },
        meta: {
          title: "Unternehmen",
          description: content["unternehmen"].index.description
        }
      },
      {
        path: "/unterhalt/",
        pathToRegexpOptions: { strict: true },
        name: "unterhalt",
        component: Content,
        props: {
          category: "unterhalt"
        },
        meta: {
          title: "Unterhalt",
          description: content["unterhalt"].index.description
        }
      },
      {
        path: "/dienstleistungen/",
        pathToRegexpOptions: { strict: true },
        name: "dienstleistungen",
        component: Content,
        props: {
          category: "dienstleistungen"
        },
        meta: {
          title: "Dienstleistungen",
          description: content["dienstleistungen"].index.description
        }
      },
      {
        path: "/zimmerei-holzbau/",
        pathToRegexpOptions: { strict: true },
        name: "zimmerei-holzbau",
        component: Content,
        props: {
          category: "zimmerei-holzbau"
        },
        meta: {
          title: "Zimmerei und Holzbau",
          description: content["zimmerei-holzbau"].index.description
        }
      },
      {
        path: "/nistkasten/",
        pathToRegexpOptions: { strict: true },
        name: "nistkasten",
        component: Nistkasten,
        meta: {
          title: "Nistkasten",
          description: "Nistkasten - Gelebte Vielfalt",
          jumbotron: "nistkasten"
        }
      },
      {
        path: "/auffrischung-terassendeck/",
        pathToRegexpOptions: { strict: true },
        name: "auffrischung-terassendeck",
        component: AuffrischungTerrassendeck,
        meta: {
          title: "Auffrischung Terrassendeck",
          description: "Auffrischung Terrassendeck",
          jumbotron: "auffrischung-terassendeck"
        }
      },
      {
        path: "/bienenhaus/",
        pathToRegexpOptions: { strict: true },
        name: "bienenhaus",
        component: Bienenhaus,
        meta: {
          title: "Bienenhaus im Park des Bäumlihofs",
          description: "Bienenhaus im Park des Bäumlihofs",
          jumbotron: "bienenhaus"
        }
      },
      {
        path: "/welche-gaube-ist-neu/",
        pathToRegexpOptions: { strict: true },
        name: "welche-gaube-ist-neu",
        component: NeueGaube,
        meta: {
          title: "Welche Gaube ist neu?",
          description:
            "Eine neue Dachgaube für mehr Wohnqualität. Welche Gaube ist neu?",
          jumbotron: "none"
        }
      },
      {
        path: "/fischergalgen/",
        pathToRegexpOptions: { strict: true },
        name: "fischergalgen",
        component: Fischergalgen,
        meta: {
          title: "«E neie Fischergalge fir Baasel. Wo stoot är?»",
          description:
            "Ein neuer Fischergalgen für Basel. Finden Sie heraus wo er steht.",
          jumbotron: "none"
        }
      },
      {
        path: "/20-jahre-vielfalt-im-holzbau/",
        pathToRegexpOptions: { strict: true },
        name: "20-jahre-vielfalt-im-holzbau",
        component: ZwanzigJahreVielfalt,
        meta: {
          title: "20 Jahre Vielfalt im Holzbau",
          description: "Seit 20 Jahren pflegen wir Vielfalt im Holzbau.",
          jumbotron: "none"
        }
      },
      {
        path: "*",
        component: PageNotFound,
        meta: {
          title: "404 - Seite nicht gefunden",
          description: "404 - Seite nicht gefunden"
        }
      }
    ]
  });

  router.beforeEach((to, from, next) => {
    // Update the title tag
    document.title = to.meta.title
      ? `${to.meta.title} | Baumann + Partner AG`
      : "Baumann + Partner AG | Die Zimmerei für Basel";

    updateMetaTag(
      "description",
      to.meta.description,
      "Mit unserem Standort in Riehen fertigt unsere Zimmerei für Basel und Umgebung. Dabei übernehmen wir zahlreiche Dienstleistungen zur Zufriedenheit der Kunden."
    );
    next();
  });

  router.afterEach(to => {
    if (to.hash) {
      setTimeout(() => VueScrollTo.scrollTo(to.hash), 400);
    } else {
      VueScrollTo.scrollTo("#app");
    }

    // Matomo
    if (!initialized && window.location.hostname !== "localhost") {
      initialized = true;
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      const baseUrl = `//${window.location.hostname}/a/`;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = baseUrl + "j";

      const head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(script);

      script.onload = () => {
        const siteId =
          window.location.hostname === "vorschau.baumann-zimmerei.ch" ? 1 : 2;
        matomo = window.Piwik.getTracker(`${baseUrl}/p`, siteId);
        matomo.enableLinkTracking();
        trackPageView(to);
      };
    }

    trackPageView(to);
  });

  return router;
}
function trackPageView(to) {
  if (matomo) {
    // Unfortunately the window location is not yet updated here
    // We need to make our own ulr using the data provided by the router
    const loc = window.location;

    // Protocol may or may not contain a colon
    let protocol = loc.protocol;
    if (protocol.slice(-1) !== ":") {
      protocol += ":";
    }
    const url = protocol + "//" + loc.host + to.fullPath;
    matomo.setCustomUrl(url);
    matomo.trackPageView(to.meta.title ? to.meta.title : to.name);
  }
}

export default {
  initialize
};
