import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import ResponsiveImage from "@/components/ResponsiveImage.vue";
import { updateMetaTag } from "./utils";

const isProd = process.env.NODE_ENV === "production";
Vue.config.productionTip = !isProd;

Vue.prototype.$isPreRendering = navigator.userAgent === "ReactSnap";

function unobscurify(obscureString, placeholder) {
  if (Vue.prototype.$isPreRendering) {
    return placeholder !== undefined ? placeholder : obscureString;
  }
  return obscureString.replace("(ät)", "@");
}
function replaceAll(str, search, replacement) {
  return str.replace(new RegExp(search, "g"), replacement);
}

const mail = "info(ät)baumann-zimmerei.ch";
const phone = "061 601 82 82";
const waNo = "076 428 33 55";
Vue.prototype.$contact = {
  mail: unobscurify(mail),
  mailto: unobscurify("mailto:" + mail, "#"),
  tel: unobscurify("tel:+41" + replaceAll(phone.substr(1), " ", ""), "#"),
  phone: unobscurify(phone),
  whatsapp: unobscurify(
    "https://wa.me/41" + replaceAll(waNo.substr(1), " ", "")
  )
};
Vue.use(VueScrollTo);

Vue.mixin({
  methods: {
    assetKey: asset => {
      if (typeof asset === "string") {
        return asset;
      }
      return asset.url;
    },
    jobOffers: () => {
      const jobsOffers = content["jobs"].items;
      return Object.keys(jobsOffers)
        .filter(key => jobsOffers[key].hidden !== true)
        .map(key => jobsOffers[key]);
    },
    contentAsset: (category, item, filename) =>
      require("@/assets/content/" + category + "/" + item.id + "/" + filename),
    updateMetaTag,
    withObscuredLinks: rawHtml => {
      if (Vue.prototype.$isPreRendering) {
        return rawHtml;
      }
      let result = replaceAll(rawHtml, "#__TEL__", Vue.prototype.$contact.tel);
      result = replaceAll(
        result,
        "#__WHATSAPP__",
        Vue.prototype.$contact.whatsapp
      );
      return result;
    }
  }
});

const context = require.context("./content/", true, /\.md$/);
const content = {};
context.keys().forEach(function(key) {
  const parts = key.split("/", 3);
  const category = parts[1];
  const name = parts[2];
  if (!content[category]) {
    content[category] = {};
    content[category].items = {};
    content[category].navItems = {};
  }
  if (name === "index.md") {
    content[category].index = context(key);
  } else {
    const item = context(key);
    content[category].items[key] = item;
    if (!item.hidden) {
      content[category].navItems[key] = item;
    }
  }
});

Vue.prototype.$content = content;

// Global components (helpers)
Vue.component("ResponsiveImage", ResponsiveImage);

new Vue({
  router: router.initialize(content),
  render: h => h(App)
}).$mount("#app");
