<script>
import { Slide } from "vue-carousel";

export default {
  extends: Slide
};
</script>
<style>
a,
iframe,
img {
  width: 100%;
  height: auto;
}
.VueCarousel-slide {
  padding: 1rem;
}
</style>
