<template>
  <header>
    <div class="logo">
      <router-link :to="{ name: 'home' }">
        <img
          src="/images/logo.svg?r=v1"
          alt="logo"
          width="168px"
          height="47px"
        />
      </router-link>
    </div>
    <div class="hamburger">
      <Hamburger
        @click.native="navigationOpen = !navigationOpen"
        :open="navigationOpen"
      />
    </div>
    <nav class="main">
      <ul
        class="nav"
        @click="navigationOpen = false"
        :class="{ hidden: !navigationOpen }"
      >
        <HeaderNav category="zimmerei-holzbau"></HeaderNav>
        <HeaderNav category="dienstleistungen"></HeaderNav>
        <HeaderNav category="unterhalt"></HeaderNav>
        <HeaderNav category="unternehmen"></HeaderNav>
        <HeaderNav :to="{ name: 'contact' }">Kontakt</HeaderNav>
      </ul>
    </nav>
  </header>
</template>

<script>
import Hamburger from "@/components/Hamburger.vue";
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  data() {
    return {
      navigationOpen: false
    };
  },
  props: {
    image: String
  },
  components: {
    Hamburger,
    HeaderNav
  }
};
</script>
<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.logo {
  width: 50%;
  margin-right: 1rem;
  max-width: 10.5rem;
  margin-bottom: auto;
  margin-top: auto;
  cursor: pointer;
  user-select: none;
}
nav {
  width: 100%;
}
.nav.hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease;
}
.nav {
  list-style: none;
  padding-left: 0;
  margin: 0;
  max-height: 100rem;
  transition: max-height 500ms ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

@media (min-width: 839px) {
  .logo {
    width: 50%;
    max-width: 14rem;
  }
  .hamburger {
    display: none;
  }
  nav {
    max-width: 40rem;
  }
  .nav a {
    padding: 0;
  }
  /* Always show the navigation */
  .nav.hidden {
    max-height: 100rem;
    overflow: visible;
    transition: max-height 300ms ease;
  }
  .nav {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .nav > li {
    line-height: 4rem;
  }
  header {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
