<template>
  <div class="wrapper">
    <h2>«E neie Fischergalge fir Baasel. Wo stoot är?»</h2>
    <ResponsiveImage
      :image="require('@/assets/content/fischergalgen/fischergalgen.jpg')"
    ></ResponsiveImage>

    <div
      v-bind:class="{ options: true, resolved: showResolution }"
      @click="clicked"
    >
      <div class="option">
        <div class="answer correct"></div>
        <ResponsiveImage
          :image="
            require('@/assets/content/fischergalgen/elsaesserrheinweg.jpg')
          "
        ></ResponsiveImage>
      </div>
      <div class="option">
        <div class="answer"></div>
        <ResponsiveImage
          :image="
            require('@/assets/content/fischergalgen/st-alban-rheinweg.jpg')
          "
        ></ResponsiveImage>
      </div>
      <div class="option">
        <div class="answer"></div>
        <ResponsiveImage
          :image="
            require('@/assets/content/fischergalgen/st-johanns-rheinweg.jpg')
          "
        ></ResponsiveImage>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showResolution: false
    };
  },
  methods: {
    clicked() {
      this.showResolution = !this.showResolution;
    }
  }
};
</script>

<style scoped>
.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.options > .option {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
}

.resolved .answer {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='1280.000000pt' height='1280.000000pt' viewBox='0 0 1280.000000 1280.000000'%3E%3Cg transform='translate(0.000000,1280.000000) scale(0.100000,-0.100000)' fill='%23000000' stroke='none' id='g6'%3E%3Cpath d='M2321 12784 c-122 -33 -105 -17 -1184 -1093 -565 -565 -1041 -1046 -1057 -1070 -94 -140 -103 -331 -23 -471 16 -28 702 -722 1877 -1897 l1851 -1853 -1856 -1857 c-1511 -1512 -1860 -1867 -1878 -1906 -29 -64 -51 -152 -51 -202 0 -59 27 -161 57 -219 39 -74 2085 -2120 2159 -2159 137 -72 291 -74 427 -6 29 14 611 590 1899 1877 l1858 1857 1852 -1851 c1176 -1175 1870 -1861 1898 -1877 149 -86 343 -70 487 38 32 23 513 499 1069 1056 765 768 1017 1026 1037 1065 73 141 74 305 0 434 -16 28 -709 729 -1877 1898 l-1851 1852 1851 1853 c1168 1168 1861 1869 1877 1897 74 129 73 293 0 434 -20 39 -272 297 -1037 1065 -556 557 -1037 1033 -1069 1056 -144 108 -338 124 -487 38 -28 -16 -722 -702 -1898 -1877 l-1852 -1851 -1858 1857 c-1288 1287 -1870 1863 -1899 1877 -100 50 -219 63 -322 35z' id='path4' style='fill:%23ff0000' /%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.resolved .answer.correct {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' version='1.0' width='1280.000000pt' height='945.000000pt' viewBox='0 0 1280.000000 945.000000'%3E%3Cg transform='translate(0.000000,945.000000) scale(0.100000,-0.100000)' fill='%23000000' stroke='none' id='g4' style='fill:%232ca02c'%3E%3Cpath d='M9499 9382 c-31 -37 -181 -215 -334 -397 -1091 -1294 -2177 -2616 -3585 -4365 -746 -926 -1002 -1242 -1272 -1568 -442 -532 -664 -762 -738 -762 -31 0 -102 71 -195 195 -164 218 -370 557 -875 1440 -488 854 -655 1128 -752 1239 l-35 40 -92 -34 c-319 -115 -692 -349 -1061 -666 -187 -161 -560 -539 -560 -567 0 -40 577 -820 1199 -1622 761 -981 1385 -1736 1670 -2024 124 -125 276 -210 469 -263 105 -29 347 -31 472 -5 102 22 319 93 412 136 77 35 94 55 378 416 498 637 740 938 1100 1370 1848 2220 3966 4322 6242 6193 211 173 723 584 801 642 25 19 47 38 47 43 0 4 -15 7 -33 7 -55 0 -343 20 -517 36 -1030 93 -1937 289 -2670 578 -10 4 -33 -16 -71 -62z' id='path2' style='fill:%232ca02c' /%3E%3C/g%3E%3C/svg%3E%0A");
}

@media (min-width: 839px) {
  .options > .option {
    width: 30%;
  }
}
</style>
